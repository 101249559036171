﻿@import '../Global-scss/variables';

form + form {
    margin-top: 0rem;
}

.element-container {
    height: 5.7rem;
}

input[type="password"]::-ms-reveal {
    display: none;
}

.report-table {
    width: 100%
}
.role-selection-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media (orientation: portrait) {
        margin-top: 2rem;
    }
}


