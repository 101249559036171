﻿@import '../Global-scss/variables';

button, html input[type=button], input[type=reset], input[type=submit] {
	cursor: pointer;
}

button, select {
	text-transform: none;
}

button {
	overflow: visible;
}



.btn, .card, .card-details, .tabs .tab-content {
	transition: background-color .1s ease-out;
	will-change: background-color;
	text-decoration: none;
}

.btn-primary {
	background: $brand-primary;
	color: #fff;
}

.btn-primary:hover {
	background: $brand-primary-light;
}

.btn-secondary {
	background: $brand-secondary;
	color: #fff;
}

.btn-secondary:hover {
	background: $brand-secondary-light;
}

.btn {
    padding: 1.25rem;
    display: inline-block;
    margin-bottom: 1rem;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    white-space: nowrap;
    line-height: 1.42857143;
    border-radius: $button-radius;
    user-select: none;
    font-family: $font;
    font-weight: 700;
    cursor: pointer;
    border: 0 none;
    box-shadow: $input-shadow;
    outline: none;
    font-size: 1rem;
    width: 100%;

    @media (min-width: $screen-md-min) {
        margin-right: 1rem;
    }

    &[disabled] {
        box-shadow: none;
        cursor: auto;
        pointer-events: none;
        opacity: .65;
    }

    &:active {
        box-shadow: none;
    }
}

.overflow {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.btn-no-margin {
    @media (min-width: $screen-md-min) {
        margin: 0;
    }
}

.btn-small {
    @media (min-width: $screen-md-min) {
        width: auto;
        min-width: 5rem;
    }
}

.btn-medium {
    @media (min-width: $screen-md-min) {
        width: auto;
        min-width: 7rem;
    }
}

.flag-icon {
    width: 3rem;
    height: 20px;
    vertical-align: middle;
    padding: 0px 10px 0px 10px;
}


.button {
    transition: background-color .1s ease-out;
    will-change: background-color;
    text-decoration: none;
    padding: 0.85rem 1.25rem;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    touch-action: manipulation;
    background-image: none;
    white-space: nowrap;
    line-height: 1.4;
    border-radius: $button-radius;
    user-select: none;
    font-family:  $font;
    font-weight: bold;
    cursor: pointer;
    border: 0 none;
    outline: none;
    font-size: 1rem;
    width: 100%;


    &[disabled] {
        box-shadow: none;
        cursor: auto;
        pointer-events: none;
        opacity: .65;
    }

    &:active {
        box-shadow: none;
    }
}
.button-role-selection{
    width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;

    @media (orientation: portrait) {
        width: 100%;
    }
}
.last-button{
    margin-bottom: 2rem;
}
.button-primary,
a.button-primary {
    background: $brand-primary;
    color: #fff;
    margin-top: 1rem;

    &:visited {
        color: #fff;
    }

    &:focus {
        box-shadow: 0 0 0 0.125rem #fff,0 0 0.0625rem 0.25rem $brand-secondary !important;
        outline: 0;
        color: #fff;
    }

}

.button-primary:hover {
    background: $brand-primary-light;
}
.button-gray-light,
a.button-gray-light{
    background: $gray-light;
    margin-top: 1rem;
}

.button-gray-light:hover {
    background: $gray-neutral;
}

.button-create-account{
    background: $gray-light;
}

.button-create-account:hover {
    background: $gray-neutral;
}

.button-secondary,
a.button-secondary{
    background: $brand-secondary;
    color: #fff;
    margin-top: 1rem;

    &:visited {
        color: #fff;
    }

    &:focus {
        box-shadow: 0 0 0 0.125rem #fff,0 0 0.0625rem 0.25rem $brand-secondary !important;
        outline: 0;
        color: #fff;
    }
}

.button-secondary:hover {
    background: $brand-secondary-light;
}
