﻿@import '../Global-scss/variables';

* {
	box-sizing: border-box;
}

html {
	font-size: 14px;

	@media (min-width: $screen-sm-min) {
		font-size: 16px;
	}
}

html, body {
	height: 100%;
	width: 100%;
	font-family: $font;
}

a {
	color: $text-color;
	font-size: 1.375rem;
	text-decoration: underline;
}

.main {
	position: relative;
	display: flex;
	width: 100%;
	height: 100%;
}

.report-table {
    border-collapse: collapse;
    td, th {
        border: 0.1em $text-color solid;
        padding: 0.3em;
    }

    th {
        text-align: left;
    }
}
