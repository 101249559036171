﻿// Breakpoints
$screen-xs:								480px !default;
$screen-xs-min:							$screen-xs !default;
$screen-sm:								768px !default;
$screen-sm-min:							$screen-sm !default;
$screen-md:								992px !default;
$screen-md-min:							$screen-md !default;

$hover-devices-only:					(hover: hover), screen and (min--moz-device-pixel-ratio: 0);

// General colors
$text-color:							#333;
$gray-default:							#dedede;
$gray-lighter:							#fafbfc;
$gray-light:							#f7f7f9;
$gray-neutral:							#eaeff2;
$gray-dark:								#c2c2c2;
$gray-darker:							#949494;

// Branding
$brand-primary:							#c32451;
$brand-primary-dark:					darken($brand-primary, 14%);
$brand-primary-light:					lighten($brand-primary, 14%);

$brand-secondary:						#34a3d7;
$brand-secondary-dark:					darken($brand-secondary, 14%);
$brand-secondary-light:					lighten($brand-secondary, 14%);
$brand-secondary-lighter:				lighten($brand-secondary, 31%);

$brand-alt-dark:						#e95f15;
$brand-alt-dark-dark:					darken($brand-alt-dark, 14%);
$brand-alt-dark-light:					lighten($brand-alt-dark, 14%);

$brand-alt-light:						#f6cf19;
$brand-alt-light-dark:					darken($brand-alt-light, 14%);
$brand-alt-light-light:					lighten($brand-alt-light, 14%);

// Progress colors
$progress-color-star:					#fde43f;
$progress-color-pos:					#9dcd1c;
$progress-color-pos-light:				#93ab50;
$progress-color-neg:					#c71717;
$progress-color-neg-light:				#dd7474;
$progress-color-stuck:					#f2d000;

//Typography
$font:									'ABeZeh', sans-serif;
$base-font-family:						$font; // used by normalize

// Buttons
$button-radius:							50px;
$button-radius-sec:						10px;

// Dimensions
$max-app-content-width:					65rem;
$half-breakpoint:						$max-app-content-width * 2;
$main-content-padding:					1.25rem;

//shadows
$input-shadow:							0 2px 1px 0 rgba(0, 0, 0, .1);
$input-shadow-sec:						0 3px 7px rgba(0, 0, 0, .07);
$input-shadow-sec-botton:				0 -3px 7px rgba(0, 0, 0, .07);
$inset-input-shadow:                    inset 0 7px 7px -7px rgba(0, 0, 0, .1)
