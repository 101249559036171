@import '../Global-scss/variables';

$font-abezeh-src: '~@snappet/assets/fonts/ABeZeh/';

@font-face {
	font-family: 'abezeh';
	font-style: normal;
	font-weight: 400;
	src:
        url("#{$font-abezeh-src}abezeh-regular.woff2") format('woff2'),
        url("#{$font-abezeh-src}abezeh-regular.woff") format('woff');
}

@font-face {
	font-family: 'abezeh';
	font-style: normal;
	font-weight: 700;
    src:
        url("#{$font-abezeh-src}abezeh-bold.woff2") format('woff2'),
        url("#{$font-abezeh-src}abezeh-bold.woff") format('woff');
}

h1 {
	font-size: 5rem;
	font-weight: 700;
	margin: 0 0;
	line-height: 6rem;
}

h2 {
	font-size: 2.25rem;
	font-weight: 700;
	line-height: 1.33;
    margin-bottom: 1rem;
}

h3 {
	font-size: 1.5rem;
	color: $text-color;
	font-weight: 700;
    margin: 0 0 1rem;
    line-height: 3rem;
}

p{
    font-size: 1.125rem;
    line-height: 1.56;
}

.text-center {
	text-align: center;
}

.hidden {
	visibility: hidden;
	line-height: 0;
}
